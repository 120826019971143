import {useProducts} from './use-products';
import {FormattedPrice} from '../../i18n/formatted-price';
import {Button} from '../../ui/buttons/button';
import {Trans} from '../../i18n/trans';
import {ForumIcon} from '../../icons/material/Forum';
import {Navbar} from '../../ui/navigation/navbar/navbar';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {Chip} from '../../ui/forms/input-field/chip-field/chip';
import {Footer} from '../../ui/footer/footer';
import {Product} from '../product';
import {Fragment, useState} from 'react';
import {findBestPrice, UpsellBillingCycle} from './find-best-price';
import {Skeleton} from '../../ui/skeleton/skeleton';
import {AnimatePresence, m} from 'framer-motion';
import {ProductFeatureList} from './product-feature-list';
import {useAuth} from '../../auth/use-auth';
import {setInLocalStorage} from '../../utils/hooks/local-storage';
import {BillingCycleRadio} from './billing-cycle-radio';
import {StaticPageTitle} from '../../seo/static-page-title';
import {opacityAnimation} from '../../ui/animation/opacity-animation';
import {PaymentSystemsLogo} from "../payment-systems-logo";

export function PricingPage() {
  const query = useProducts();
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');

  return (
    <div className="flex flex-col h-full overflow-auto">
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <Navbar
        color="bg"
        border="border-b"
        className="flex-shrink-0"
        menuPosition="pricing-table-page"
      />
      <div className="container mx-auto px-24 flex-auto">
        <h1 className="text-3xl md:text-4xl text-center mt-30 md:mt-60 mb-30 font-normal md:font-medium">
          <Trans message="Choose the right plan for you" />
        </h1>

        <BillingCycleRadio
          products={query.data?.products}
          selectedCycle={selectedCycle}
          onChange={setSelectedCycle}
          className="mb-40 md:mb-70 flex justify-center billing-cycle-radio"
          size="lg"
        />

        <div className="plan-list flex flex-col md:flex-row items-stretch pb-20 overflow-x-auto overflow-y-visible">
          <AnimatePresence initial={false} mode="wait">
            {query.data ? (
              <PlanList
                key="plan-list"
                plans={query.data.products}
                selectedPeriod={selectedCycle}
              />
            ) : (
              <SkeletonLoader key="skeleton-loader" />
            )}
          </AnimatePresence>
        </div>
        <PaymentSystemsLogo className="mt-40" />
        <ContactSection />
      </div>
      <Footer className="container mx-auto px-24 flex-shrink-0" />
    </div>
  );
}

interface PlanListProps {
  plans: Product[];
  selectedPeriod: UpsellBillingCycle;
}
function PlanList({plans, selectedPeriod}: PlanListProps) {
  const {isLoggedIn, isSubscribed} = useAuth();

  // only show free plans to guest users
  const filteredPlans = plans
    .filter(plan => !plan.hidden && (!plan.free || !isLoggedIn))
    //.slice(0, 1); // TODO: It was feature OR bug?

  return (
    <Fragment>
      {filteredPlans.map((plan, index) => {
        const isFirst = index === 0;
        const isLast = index === filteredPlans.length - 1;
        const price = findBestPrice(selectedPeriod, plan.prices);

        let upgradeRoute = `/checkout/${plan.id}/${price?.id}`;
        if (!isLoggedIn) {
          upgradeRoute = `/register?redirectFrom=pricing`;
        }
        // Пока убираем этот вариант, так как завязан на подписки
        /*if (isSubscribed) {
          upgradeRoute = `/change-plan/${plan.id}/${price?.id}/confirm`;
        }*/

        return (
          <m.div
            {...opacityAnimation}
            key={plan.id}
            className={clsx(
              'px-28 rounded-lg w-full md:max-w-350 md:min-w-240 border shadow-lg bg-paper',
              plan.recommended ? 'py-56' : 'py-28',
              isFirst && 'ml-auto',
              isLast && 'mr-auto'
            )}
          >
            <div className="mb-32">
              <Chip
                radius="rounded"
                size="sm"
                className={clsx(
                  'mb-20 w-min',
                  !plan.recommended && 'invisible'
                )}
              >
                <Trans message="Most popular" />
              </Chip>
              <div className="text-xl font-semibold mb-12">
                <Trans message={plan.name} />
              </div>
              <div className="text-sm text-muted">
                <Trans message={plan.description} />
              </div>
            </div>
            <div>
              <FormattedPrice
                priceClassName="font-bold text-4xl"
                periodClassName="text-muted text-xs"
                variant="separateLine"
                price={price}
              />
              <div className="mt-60">
                <Button
                  variant="flat"
                  color="primary"
                  className="w-full"
                  size="md"
                  elementType={upgradeRoute ? Link : undefined}
                  onClick={() => {
                    if (isLoggedIn || !price || !plan) return;
                    setInLocalStorage('be.onboarding.selected', {
                      productId: plan.id,
                      priceId: price.id,
                    });
                  }}
                  to={upgradeRoute}
                >
                  {plan.free ? (
                    <Trans message="Get started" />
                  ) : (
                    <Trans message="Upgrade" />
                  )}
                </Button>
              </div>
              <ProductFeatureList product={plan} />
            </div>
          </m.div>
        );
      })}
    </Fragment>
  );
}

function ContactSection() {
  return (
    <div className="p-24 text-center my-20 md:my-80">
      <ForumIcon size="xl" className="text-muted" />
      <div className="md:text-lg my-8">
        <Trans message="Do you have any questions about PRO accounts?" />
      </div>
      <div className="mb-24 text-sm md:text-base mt-20 md:mt-0">
        <Trans message="Our support team will be happy to assist you." />
      </div>
      <Button variant="flat" color="primary" elementType={Link} to="/contact">
        <Trans message="Contact us" />
      </Button>
    </div>
  );
}

function SkeletonLoader() {
  return (
    <Fragment>
      <PlanSkeleton key="skeleton-1" />
      <PlanSkeleton key="skeleton-2" />
      <PlanSkeleton key="skeleton-3" />
    </Fragment>
  );
}

function PlanSkeleton() {
  return (
    <m.div
      {...opacityAnimation}
      className="px-28 py-90 rounded-lg border shadow-lg w-full md:max-w-350"
    >
      <Skeleton className="my-10" />
      <Skeleton className="mb-40" />
      <Skeleton className="h-30 mb-40" />
      <Skeleton className="h-40 mb-40" />
      <Skeleton className="mb-20" />
      <Skeleton />
      <Skeleton />
    </m.div>
  );
}
