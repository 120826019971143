import clsx from "clsx";

interface Props {
  className?: string;
}

export function PaymentSystemsLogo({className}: Props) {
  return (<div
    className={clsx( 'flex justify-center pt-10 md:pt-0', className)}>

    <img src="/client/assets/ext/images/visa-icon.png"
         alt="visa logo"
         style={{
           height: '40px',
           marginInlineEnd: '5px'
        }}
    />

    <img src="/client/assets/ext/images/master-icon.png"
         alt="master logo"
         style={{
           height: '40px'
         }}
    />
  </div>);
}
